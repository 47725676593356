import React from "react";
import { useTranslation } from "react-i18next";
import dollarSignNew from "../static/dollarSignNew.png";
import checkMarkNew from "../static/checkMarkNew.png";

export default function LoanCard() {

    const { t } = useTranslation();

    return (
        <div className="w-full md:w-4/12 px-4 mb-8">
            <div className="relative flex flex-col min-w-0 break-words bg-color3 w-full h-auto mb-8 shadow-lg rounded-lg p-6">
                <div className="px-4 py-5 flex-auto">
                    <div className="text-4xl font-semibold text-color1 mb-4">{t("loan")}</div>
                    <h6 className="text-color1 text-sm font-semibold">{t("loanParagraph")}</h6>
                    <div className="text-md font-semibold text-color1 mt-6">{t("benefits")}</div>
                    <ul className="list-none text-sm font-semibold text-color1 mb-4">
                        <li className="py-2 flex items-center">
                            <img className="icon2" src={checkMarkNew} alt="checkmark"></img>
                            <h4 className="text-color1 ml-4">
                                {t("loanBenefits1")}
                            </h4>
                        </li>
                        <li className="py-2 flex items-center">
                            <img className="icon2" src={checkMarkNew} alt="checkmark"></img>
                            <h4 className="text-color1 ml-4">
                                {t("loanBenefits2")}
                            </h4>
                        </li>
                        <li className="py-2 flex items-center">
                            <img className="icon2" src={checkMarkNew} alt="checkmark"></img>
                            <h4 className="text-color1 ml-4">
                                {t("loanBenefits3")}
                            </h4>
                        </li>
                    </ul>

                    <div className="text-md font-semibold text-color1 mt-6">{t("payments")}</div>
                    <ul className="list-none text-sm font-semibold text-color1 mb-4">
                        <li className="py-2 flex items-center">
                            <img className="icon2" src={dollarSignNew} alt="dollarsign"></img>
                            <h4 className="text-color1 ml-4">
                                {t("loanPayments1")}
                            </h4>
                        </li>
                        <li className="py-2 flex items-center">
                            <img className="icon2" src={dollarSignNew} alt="dollarsign"></img>
                            <h4 className="text-color1 ml-4">
                                {t("loanPayments2")}
                            </h4>
                        </li>
                    </ul>
                    <div className="flex justify-center">
                        <a
                            className={
                                "bg-color1 hover:bg-color3 text-color3 hover:text-color1 active:bg-white text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                            }
                            href="#contactForm"
                            style={{ transition: "all .15s ease" }}

                        >
                            <i className="fas fa-arrow-alt-circle-down"></i> {t("contactUsNow")}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}