import React, { useState } from "react";
import { useRef } from "react";
import emailjs from '@emailjs/browser';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import trustIcon from '../static/trustIcon.png';
import solarPanelNewBackground from '../static/solarPanelNewBackground.jpeg';
import cleanEnergyIcon from '../static/cleanEnergyIcon.png';
import monitoringIcon from '../static/monitoringIcon.png';
import howItWorks from '../static/howItWorksImage.webp';
import { useTranslation } from "react-i18next";
import CardPanelBackground from "../static/CardPanelBackground.jpeg";
import LeaseCard from "../components/LeaseCard";
import LoanCard from "../components/LoanCard";
import ContactFormBackground from "../static/ContactFormBackground.jpeg";

export default function HomePage() {

  const { t } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [phone, setPhone] = useState('');
  const [ownHome, setOwnHome] = useState('');

  // Event handlers to update state variables
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleZipCodeChange = (e) => {
    setZipCode(e.target.value);
  }

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  }

  const handleOwnHomeChange = (e) => {
    setOwnHome(e.target.value);
  };

  const form = useRef();

  // Function to handle form submission
  const handleFormSubmit = async (e) => {
    const templateParams = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      zipCode: zipCode,
      phone: phone,
      ownHome: ownHome
    };

    e.preventDefault();

    emailjs
      .sendForm('service_e0m7i6a', 'template_jdx1vv9', form.current, {
        publicKey: 'B3_hFGTYiLmoMOWFi',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

    // Clear form fields
    setFirstName('');
    setLastName('');
    setEmail('');
    setZipCode('');
    setPhone('');
    setOwnHome('');
  };

  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh"
          }}>
          <div className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: `url(${solarPanelNewBackground})`
            }}>
          </div>
          <div
            style={{
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
          ></div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-color3 font-semibold text-5xl">
                    {t("openingLine")}
                  </h1>
                  <p className="mt-4 mb-7 text-lg text-color3">
                    {t("openingParagraph")}
                  </p>
                  <a
                    className={
                      "bg-color1 hover:bg-color3 text-color3 hover:text-color1 active:bg-white text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                    }
                    href="#contactForm"
                    style={{ transition: "all .15s ease" }}

                  >
                    <i className="fas fa-arrow-alt-circle-down"></i> {t("contactUsNow")}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-current text-color4"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gradient-to-r from-color3 to-color4 -mt-24"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${CardPanelBackground})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}>
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">

              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center relative">
                <div
                  className="relative flex flex-col min-w-0 break-words bg-color1 w-full mb-8 shadow-lg rounded-lg"
                >
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-color2">
                      <img
                        className="icon1"
                        src={trustIcon}
                      >
                      </img>
                    </div>
                    <h6 className="text-color3 text-xl font-semibold">{t("goSolar")}</h6>
                    <p className="mt-2 mb-4 text-color3">
                      {t("goSolarParagraph")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-color1 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-color2">
                      <img
                        className="icon2"
                        src={cleanEnergyIcon}
                      >
                      </img>
                    </div>
                    <h6 className="text-color3 text-xl font-semibold">
                      {t("reduceDependence")}
                    </h6>
                    <p className="mt-2 mb-4 text-color3">
                      {t("gridPower")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-color1 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-color2">
                      <img
                        className="icon1"
                        src={monitoringIcon}
                      >
                      </img>
                    </div>
                    <h6 className="text-color3 text-xl font-semibold">
                      {t("monitoring")}
                    </h6>
                    <p className="mt-2 mb-4 text-color3">
                      {t("industryLeading")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap justify-center items-center mt-32">
              <LeaseCard />
              <LoanCard />
            </div>
          </div>
        </section>

        <section className="relative py-20 bg-gradient-to-r from-color3 to-color4">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-color4 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src={howItWorks}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 className="text-color1 text-3xl font-semibold">
                    {t("howItWorks")}
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-color1">

                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <h1 className="text-color1 text-xl">
                            {t("stepOne")}
                          </h1>
                          <h4 className="text-color1 ml-7 mt-3">
                            {t("stepOneDesc")}
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <h1 className="text-color1 text-xl">
                            {t("stepTwo")}
                          </h1>
                          <h4 className="text-color1 ml-7 mt-3">
                            {t("stepTwoDesc")}
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <h1 className="text-color1 text-xl">
                            {t("stepThree")}
                          </h1>
                          <h4 className="text-color1 ml-7 mt-3">
                            {t("stepThreeDesc")}
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-gradient-to-r from-color3 to-color4">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-color4 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64" />

        </section>

        <section className="relative block py-24 lg:pt-0 bg-color1"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${ContactFormBackground})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}>
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <form ref={form} onSubmit={handleFormSubmit} className="w-full lg:w-6/12 px-4 pt-20" id="contactForm">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-color3 hover:drop-shadow">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl text-color1 font-semibold">
                      {t("contactUsForQuote")}
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-color1">
                      {t("completeThisForm")}
                    </p>

                    <div className="flex items-center">
                      <div className="relative w-full mb-3 mt-8">
                        <label
                          className="block uppercase text-color1 text-xs font-bold mb-2"
                          htmlFor="first-name"
                        >
                          {t("firstName")}
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring"
                          placeholder={t("firstName")}
                          style={{ transition: "all .15s ease" }}
                          value={firstName}
                          onChange={handleFirstNameChange}
                        />
                      </div>

                      <div className="relative w-full mb-3 mt-8">
                        <label
                          className="block uppercase text-color1 text-xs font-bold mb-2"
                          htmlFor="last-name"
                        >
                          {t("lastName")}
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring"
                          placeholder={t("lastName")}
                          style={{ transition: "all .15s ease" }}
                          value={lastName}
                          onChange={handleLastNameChange}
                        />
                      </div>
                    </div>

                    <div className="flex items-center">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-color1 text-xs font-bold mb-2"
                          htmlFor="email"
                        >
                          {t("email")}
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring"
                          placeholder={t("email")}
                          style={{ transition: "all .15s ease" }}
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-color1 text-xs font-bold mb-2"
                          htmlFor="email"
                        >
                          {t("zipCode")}
                        </label>
                        <input
                          type="zipcode"
                          name="zipCode"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring"
                          placeholder={t("zipCode")}
                          style={{ transition: "all .15s ease" }}
                          value={zipCode}
                          onChange={handleZipCodeChange}
                        />
                      </div>
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-color1 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        {t("phone")}
                      </label>
                      <input
                        type="phone"
                        name="phone"
                        className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder={t("phone")}
                        style={{ transition: "all .15s ease" }}
                        value={phone}
                        onChange={handlePhoneChange}
                      />
                    </div>

                    <label
                      className="block uppercase text-color1 text-xs font-bold mb-2 mt-7"
                      htmlFor="homeOwner"
                    >
                      {t("ownHome?")}
                    </label>
                    <div class="">
                      <input
                        id="radio-yes"
                        type="radio"
                        name="ownHome"
                        class="w-4 h-4 
                        checked:bg-color1 text-color1 border-color4 focus:ring-color1"
                        value="yes"
                        checked={ownHome === 'yes'}
                        onChange={handleOwnHomeChange} />
                      <label for="radio-yes" class="ms-2 text-sm font-medium text-color1 dark:text-color1">{t("yes")}</label>
                    </div>
                    <div class="">
                      <input
                        id="radio-no"
                        type="radio"
                        name="ownHome"
                        class="w-4 h-4 
                        checked:bg-color1 text-color1 border-color4 focus:ring-color1"
                        value="no"
                        checked={ownHome === 'no'}
                        onChange={handleOwnHomeChange} />
                      <label for="radio-no" class="ms-2 text-sm font-medium text-color1 dark:color1">No</label>
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-color1 text-color3 active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={handleFormSubmit}
                      >
                        {t("sendMessage")}
                      </button>
                      <h6 className="leading-relaxed mt-7 mb-4 text-color1 text-xs">
                        {t("messageConsent")}
                      </h6>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}