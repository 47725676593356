import React, { useState } from "react";
import i18n from 'i18next';

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [language, setLanguage] = useState('en');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  
  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'es' : 'en';
    setLanguage(newLanguage);
    changeLanguage(newLanguage); // Assuming changeLanguage is a provided function
  };

  return (
    <nav
      className={
        (props.transparent
          ? "top-0 absolute z-50 w-full"
          : "relative bg-color1 shadow-lg") +
        " flex flex-wrap items-center justify-between px-2 py-3 "
      }
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a
            className={
              (props.transparent ? "text-color3" : "text-color3") +
              " text-lg font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            }
            href="#"
          >
            DaylySun
          </a>
        </div>
        <div className="flex items-center">
          <ul className="flex flex-col lg:flex-row list-none mr-auto">
            {/* Conditional rendering for language buttons */}
            <li className="flex items-center">
              <div>
                <button
                  className={
                    "bg-color1 hover:bg-color3 text-color3 hover:text-color1 active:bg-white text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ml-3 mb-3"
                  }
                  type="button"
                  style={{ transition: "all .15s ease" }}
                  onClick={toggleLanguage}
                >
                  <i className="fas fa-arrow-alt-circle-down"></i> {language === 'en' ? 'Para Español' : 'For English'}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
